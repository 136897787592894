import React, { useState } from "react";
import useSearchState from "Hooks/useSearchState";
import { matchSorter } from "match-sorter";
import Moment from "moment";
import Masonry from "react-masonry-css";
import ListItem from "Components/ListItem";
import Dismiss from "Svg/Dismiss";
import Heart from "Svg/Heart";
import HeartFilledWhite from "Svg/HeartFilledWhite";
import styles from "./search.module.scss";

function Search({ lists, isUserLoggedIn }) {
  const { searchQuery, setSearchQuery, showLiked, setShowLiked } = useSearchState();
  const filteredLists = searchQuery
    ? matchSorter(lists, searchQuery, { keys: ["title", (list) => list.tags.map((tag) => tag.name)] })
    : lists;

  const likedLists = showLiked ? filteredLists.filter((list) => list.isLikedByUser) : filteredLists;
  return (
    <>
      <section className={styles.searchWrapper}>
        <h1 className={styles.cityTitle}>Austin</h1>
        <div className={styles.search}>
          <div className={styles.inputWrapper}>
            <input
              className={styles.input}
              type="Search"
              name="Search"
              id="my-input"
              title="Search Lists"
              placeholder="Filter lists by keyword"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target?.value ?? "")}
            />
            {searchQuery && (
              <button onClick={(e) => setSearchQuery("")} className={styles.cancelButton}>
                <Dismiss />
              </button>
            )}
          </div>
          {isUserLoggedIn ? (
            showLiked ? (
              <button
                onClick={() => {
                  setShowLiked(false);
                }}
                className={styles.likeHeartWrapper}
              >
                <HeartFilledWhite />
              </button>
            ) : (
              <button
                onClick={() => {
                  setShowLiked(true);
                }}
                className={styles.heartWrapper}
              >
                <Heart />
              </button>
            )
          ) : (
            <button
              onClick={() => {
                document.getElementById("login").click();
              }}
              className={styles.heartWrapper}
            >
              <Heart />
            </button>
          )}
        </div>
      </section>
      <Lists lists={likedLists} />
    </>
  );
}

const breakpointColumnsObj = {
  default: 5,
  1200: 4,
  920: 3,
  640: 2,
};

function Lists({ lists }) {
  return (
    <Masonry className={styles.grid} columnClassName={styles.gridColumn} breakpointCols={breakpointColumnsObj}>
      {lists.map((list) => {
        return (
          <ListItem
            alt={list.title}
            key={list.customId}
            imgSrc={list.image.asset.fluid}
            likeCount={list.likeCount}
            listKey={list.customId}
            slug={`/list/${list.slug.current}`}
            tagNames={list.tags}
            title={list.title}
            time={getTime(list.activeDate.local)}
            userLiked={list.isLikedByUser}
          />
        );
      })}
    </Masonry>
  );
}

function getTime(timestamp) {
  return new Moment(timestamp).format("dddd, MMMM D");
}

export default Search;
