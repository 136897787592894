import React from "react";
import { graphql } from "gatsby";
import { gql, useQuery } from "@apollo/client";
import useAuthState from "Hooks/useAuthState";
import Home from "Features/Home";
import SEO from "Components/SEO";
import ToastContainer from "Components/ToastContainer";

function RootIndex({ data }) {
  const { authState } = useAuthState();

  const likesQuery = useQuery(FETCH_LIKES, {
    fetchPolicy: "network-only",
    variables: {
      user: authState?.user?.email ?? "",
    },
  });

  const lists = data?.allSanityLists?.edges;

  if (likesQuery.data) {
    const { list_like_count, list_likes } = likesQuery.data;
    const listsWithLikeData = lists.map(({ node: listItem }) => {
      const likeCount = list_like_count.find((listLike) => listLike?.list_id === listItem.customId)?.count;
      const isLikedByUser = list_likes.some((listLike) => listLike.list_id === listItem.customId);
      return { ...listItem, likeCount, isLikedByUser };
    });

    return (
      <>
        <SEO title="Home" />
        <Home authState={authState} lists={listsWithLikeData} />
        <ToastContainer />
      </>
    );
  }

  return null;
}

export const query = graphql`
  query {
    allSanityLists(sort: { fields: [activeDate___local], order: ASC }) {
      edges {
        node {
          customId
          activeDate {
            local
          }
          _rawDescription
          title
          image {
            asset {
              fluid(maxWidth: 1000, maxHeight: 544) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
          experienceStop {
            place {
              ticket
              ticketLink
              title
              ...placeFields
            }
            event {
              ticket
              ticketLink
              title
              ...eventFields
            }
          }
          tags {
            name
          }
        }
      }
    }
  }
`;

const FETCH_LIKES = gql`
  query($user: String) {
    list_like_count {
      count
      list_id
    }
    list_likes(where: { user_id: { _eq: $user } }) {
      list_id
    }
  }
`;

export default RootIndex;
