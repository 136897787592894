import React from "react";
// Components
import Search from "Components/Search";
import XupFooter from "Components/XupFooter";
import SocialIcon from "Components/SocialIcon";
// Svg
import FacebookIcon from "Svg/FacebookIcon";
import InstagramIcon from "Svg/InstagramIcon";
// Hooks
import useWindowWidth from "Hooks/useWindowWidth";
// Styles
import styles from "./home.module.scss";

function Home({ authState, dateRangeLowerLimit, lists }) {
  const isLoggedIn = authState.status === "in";
  let width = useWindowWidth();
  return (
    <>
      <div className={styles.homeWrapper}>
        <Search lists={lists} isUserLoggedIn={isLoggedIn} />
        <section className={styles.caption}>
          <p>
            Brought to you by the humans at XUP <span alt="Emoji for XUP">🙅</span>
          </p>
        </section>
        <footer className={styles.footer}>
          {width >= 1024 && (
            <section className={styles.social}>
              <SocialIcon link="https://www.instagram.com/xupapp/">
                <InstagramIcon />
              </SocialIcon>
              <SocialIcon link="https://www.facebook.com/whatsxup/">
                <FacebookIcon />
              </SocialIcon>
            </section>
          )}
          {width >= 1024 && (
            <section className={styles.links}>
              <XupFooter />
            </section>
          )}
          {width <= 1024 && (
            <section className={styles.links} style={{ width: "100%", marginTop: "30px" }}>
              <XupFooter />
            </section>
          )}
        </footer>
      </div>
    </>
  );
}

export default Home;
