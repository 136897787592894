import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import HeartFilled from "Svg/HeartFilled";
import Heart from "Svg/Heart";
import { Link } from "gatsby";
import styles from "./listItem.module.scss";

const overlayClassNames = ["red", "purple", "teal", "darkPurple", "lightRed"];

function Tags({ tagNames }) {
  return Object.keys(tagNames).map(function (key) {
    return <p key={tagNames[key].name}>{tagNames[key].name}</p>;
  });
}

const ListItem = ({ title, time, likeCount, listKey, slug, alt, imgSrc, liked, userLiked, tagNames }) => {
  function LikeCount() {
    if (likeCount < 1 || !likeCount) {
      return (
        <>
          <p>0</p>
        </>
      );
    }
    if (likeCount === 1) {
      return (
        <>
          <p>{likeCount}</p>
        </>
      );
    }
    if (likeCount > 1) {
      return <p>{likeCount}</p>;
    }
  }

  return (
    <div className={styles.container} key={listKey}>
      <Link to={slug} alt={alt}>
        <section className={styles.imgWrapper}>
          <Image alt={alt} fluid={imgSrc} className={styles.image} />
          <div className={styles.placeholderLikes}>
            {userLiked ? <HeartFilled /> : <Heart />}
            <LikeCount />
          </div>
        </section>
        <section className={styles.info}>
          <h2>{title}</h2>
          <p className={styles.time}>{time}</p>
          <div className={styles.tagsWrapper}>
            <Tags tagNames={tagNames} />
          </div>
        </section>
      </Link>
    </div>
  );
};

ListItem.propTypes = {
  title: PropTypes.string,
  time: PropTypes.string,
  likeCount: PropTypes.number,
  listKey: PropTypes.string,
  slug: PropTypes.string,
  alt: PropTypes.string,
  imgSrc: PropTypes.string,
  liked: PropTypes.bool,
  tagNames: PropTypes.array,
  userLiked: PropTypes.bool,
};

export default ListItem;
